@charset "UTF-8";
.swiper-slide {
  height: 820px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-container {
  --swiper-pagination-color: #c70100;
  /* 两种都可以 */
}
.swiper-container:hover .swiper-button {
  display: block;
}
.swiper-container .swiper-button {
  display: none;
  width: 120px;
  height: 820px;
  top: 0;
  margin-top: 0;
}
.swiper-container .swiper-button::after {
  content: "";
}
.swiper-container .swiper-button.swiper-button-next {
  right: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.swiper-container .swiper-button.swiper-button-prev {
  left: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
}
.swiper-container .swiper-pagination {
  left: 30%;
}
.swiper-container .swiper-pagination span {
  margin: 0 20px !important;
  width: 14px;
  height: 14px;
}

.taiyouxi-intro {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.taiyouxi-intro .intro-item {
  width: 380px;
}

.box-title {
  padding-bottom: 8px;
  margin-bottom: 15px;
  border-bottom: 2px solid #DCDADA;
}

.box-title h3 {
  display: inline;
  margin-right: 10px;
  font-size: 20px;
}

.box-title i {
  color: #bbb;
  font-style: normal;
  font-size: 12px;
}

.box-title .more {
  float: right;
  margin-top: 7px;
}

.box-title a {
  color: #999;
}

.box-title a:hover {
  color: #333;
}

.box-title:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  line-height: 0;
  font-size: 0;
}

.company-image {
  margin-top: 15px;
}
.company-image a {
  font-size: 0;
}
.company-image a img {
  width: 33.3%;
}

.job-list {
  margin: 0;
  padding: 0;
  list-style: none;
  background: url(../images/bg-map.png) no-repeat right 15px;
}

.job-list li {
  margin-bottom: 8px;
}

.job-list li a {
  color: #959494;
  font-size: 18px;
}

.job-list li a:hover {
  color: #000;
}

.service {
  height: 225px;
  background: url(../images/bg-service.png) no-repeat right bottom;
}

.service h5 {
  margin-bottom: 5px;
  color: #717171;
  font-size: 16px;
  font-family: auto;
}
.service h5:last-child {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
          -ms-flex-pack: distribute;
              justify-content: space-evenly;
  font-weight: normal;
}
.service h5 a {
  color: #c70100;
}

.service p {
  margin: 5px 0;
  color: #959494;
  font-size: 16px;
}

.service * + h5 {
  margin-top: 15px;
  font-size: 16px;
}

/*************************************** 其他样式 *****************************************/
.heightA {
  height: 225px;
  overflow: hidden;
}
.heightA > p {
  font-size: 16px;
  color: #959494;
}

.more2 {
  padding: 6px 10px;
  text-align: right;
  background-color: #E7E7E7;
}

.more2 a {
  color: #999;
}

.more2 a:hover {
  color: #333;
}

@media screen and (max-width: 640px) {
  .swiper-container .swiper-slide {
    width: 100vw;
    height: calc(100vw / 375 * 420 - 0px);
    background-repeat: no-repeat;
  }
  .swiper-container .swiper-slide.slide2 {
    background-image: url("../images/banner-jws-mobile.jpg") !important;
  }
  .swiper-container .swiper-slide.slide3 {
    background-image: url("../images/banner-sama-mobile.jpg") !important;
  }
  .swiper-container .swiper-slide.slide4 {
    background-image: url("../images/banner-jws2-mobile.jpg") !important;
  }

  .swiper-container .swiper-pagination {
    left: 0;
  }
  .swiper-container .swiper-pagination span {
    margin: 0 2px !important;
    width: 24px;
    height: 1px;
  }
  .swiper-container .swiper-pagination span.swiper-pagination-bullet {
    background: #fff;
    opacity: 1;
  }
  .swiper-container .swiper-pagination span.swiper-pagination-bullet-active {
    background: #c70100;
  }

  .taiyouxi-intro {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 20px;
  }
  .taiyouxi-intro .intro-item {
    width: 100%;
    padding: 0 20px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-bottom: 30px;
  }
  .taiyouxi-intro .intro-item .box-title {
    height: 40px;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    position: relative;
    padding-left: 16px;
    padding-bottom: 0;
    margin-bottom: 20px;
  }
  .taiyouxi-intro .intro-item .box-title::before {
    content: "";
    position: absolute;
    top: -2px;
    left: 0;
    width: 6px;
    height: 42px;
    background: #c70100;
  }
  .taiyouxi-intro .intro-item .box-title h3 {
    color: #c70100;
    font-size: 18px;
  }
  .taiyouxi-intro .intro-item .box-title i {
    font-size: 12px;
  }
  .taiyouxi-intro .intro-item .more2 {
    text-align: center;
  }
  .taiyouxi-intro .intro-item .more2 a {
    display: block;
  }
  .taiyouxi-intro .intro-item .job-list li {
    text-align: center;
  }
  .taiyouxi-intro .intro-item .heightA {
    height: auto !important;
  }
  .taiyouxi-intro .intro-item .company-image {
    margin-bottom: 10px;
  }
  .taiyouxi-intro .intro-item .service {
    height: auto;
  }
  .taiyouxi-intro .intro-item .service h5:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .taiyouxi-intro .intro-item .service h5:last-child span {
    margin: 0 10px;
  }
}